// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DigitalMarketing{
    padding: 10px 50px;
}
.DigitalMarketing header h1 {
    color: var(--primary-blue);
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
  }
 .DigitalMarketing header p{
    /* font-weight: bolder; */
    line-height: 30px;
    color:var(--primary-grey);
    font-family: Arial, Helvetica, sans-serif;
  }

  .DigitalMarketing-flex-box{
    display: flex;
    gap: 50px;
  }

  .DigitalMarketing-flex-box img{
    width: 600px;
  }

  .DigitalMarketing-flex-box p{
    color: var(--primary-grey);
  }
  .DigitalMarketing-flex-box h3{
    color: var(--primary-grey);
  }
  .DigitalMarketing-flex-box ul li{
    color: var(--primary-grey);
  }

  @media (max-width: 1024px){
    .DigitalMarketing {
      padding: 20px;
    }
    .DigitalMarketing-flex-box{
      align-items: center;
      flex-direction: column;
    }
    .DigitalMarketing-flex-box img{
      width: 100%;
      max-width: 550px;
    }
    
  .DigitalMarketing-flex-box p{
    text-align: justify;
  }
  }`, "",{"version":3,"sources":["webpack://./src/Components/IT_Service/DigitalMarketing/DigitalMarketing.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,0BAA0B;IAC1B,yCAAyC;IACzC,kBAAkB;EACpB;CACD;IACG,yBAAyB;IACzB,iBAAiB;IACjB,yBAAyB;IACzB,yCAAyC;EAC3C;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;;EAEA;IACE;MACE,aAAa;IACf;IACA;MACE,mBAAmB;MACnB,sBAAsB;IACxB;IACA;MACE,WAAW;MACX,gBAAgB;IAClB;;EAEF;IACE,mBAAmB;EACrB;EACA","sourcesContent":[".DigitalMarketing{\n    padding: 10px 50px;\n}\n.DigitalMarketing header h1 {\n    color: var(--primary-blue);\n    font-family: Arial, Helvetica, sans-serif;\n    text-align: center;\n  }\n .DigitalMarketing header p{\n    /* font-weight: bolder; */\n    line-height: 30px;\n    color:var(--primary-grey);\n    font-family: Arial, Helvetica, sans-serif;\n  }\n\n  .DigitalMarketing-flex-box{\n    display: flex;\n    gap: 50px;\n  }\n\n  .DigitalMarketing-flex-box img{\n    width: 600px;\n  }\n\n  .DigitalMarketing-flex-box p{\n    color: var(--primary-grey);\n  }\n  .DigitalMarketing-flex-box h3{\n    color: var(--primary-grey);\n  }\n  .DigitalMarketing-flex-box ul li{\n    color: var(--primary-grey);\n  }\n\n  @media (max-width: 1024px){\n    .DigitalMarketing {\n      padding: 20px;\n    }\n    .DigitalMarketing-flex-box{\n      align-items: center;\n      flex-direction: column;\n    }\n    .DigitalMarketing-flex-box img{\n      width: 100%;\n      max-width: 550px;\n    }\n    \n  .DigitalMarketing-flex-box p{\n    text-align: justify;\n  }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
