import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logoimg from "./Image/logo.png";
import {
  FaChevronUp,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTelegramPlane,
  FaWhatsapp,
} from "react-icons/fa";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { TbInfoSquareRoundedFilled } from "react-icons/tb";
import { IoMail } from "react-icons/io5";

const legalPages = [
  {
    name: "Privacy Policy",
    link: "/PrivacyPolicy",
  },
  {
    name: "Terms & Conditions",
    link: "/TermCondition",
  },
  {
    name: "Refunds & Cancellations",
    link: "/RefundCancellationPolicy",
  },
  {
    name: "Data Use Policy",
    link: "/DataUserPolicy",
  },
];

const aboutPages = [
  {
    name: "About Us",
    link: "/About",
  },
  {
    name: "Career",
    link: "/Career",
  },
];

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const year = new Date().getFullYear();

  return (
    <footer className="footer-section">
      <div className="footer-container">
        <div className="footer-left-container">
          <a href="/">
            <img src={logoimg} alt="" />
          </a>
          <div className="footer-address-section">
            <div>
              <ul>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <FaLocationDot style={{ color: "#8084FF" }} />
                  kolkata, India
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <FaPhoneAlt style={{ color: "#8084FF" }} /> +91 8001440055
                </li>
                <li>
                  <a
                    href="mailto:contact@pnsoftware.org"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <IoMail
                      style={{ width: "20px", height: "20px", fill: "#8084FF" }}
                    />{" "}
                    contact@pnsoftware.org
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:billing@pnsoftware.org"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      color: "black",
                      textDecoration: "none",
                    }}
                  >
                    <TbInfoSquareRoundedFilled
                      style={{
                        width: "20px",
                        height: "20px",
                        fill: "#8084FF",
                      }}
                    />{" "}
                    billing@pnsoftware.org
                  </a>
                </li>
              </ul>
              <Link to="/Contact">
                <button style={{ fontSize: "14px" }}>Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-right-container">
          <div className="footer-route-section">
            <h3 style={{ color: "#6d72f6" }}>LEGAL</h3>
            <ul className="footer-route-list">
              {legalPages.map((page, id) => (
                <li key={id} style={{ marginTop: "10px" }}>
                  <Link to={page.link}>{page.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-route-section">
            <h3 style={{ color: "#6d72f6" }}>ABOUT</h3>
            <ul className="footer-route-list">
              {aboutPages.map((page, id) => (
                <li key={id} style={{ marginTop: "10px" }}>
                  <Link to={page.link}>{page.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer-right-top-box">
            <div className="footer-right-box">
              <h3>FOLLOW US</h3>
              <div className="footer-right-icon-box">
                <a href="#">
                  <FaInstagram />
                </a>
                <a href="#">
                  <FaWhatsapp />
                </a>
                <a href="#">
                  <FaTelegramPlane />
                </a>
                <a href="#">
                  <FaXTwitter />
                </a>
                <a href="#">
                  <FaFacebookF />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-right-bottom-box">
            <div className="footer-scroll-btn" onClick={scrollToTop}>
              <FaChevronUp />
            </div>
          </div>
          <div className="footer-copyright">
            <p>All rights reserved ©{year}</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
