// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Plus Jakarta Sans", sans-serif;
}

:root,
body {
  height: 100%;
}

.page-route-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.page-options {
  width: 100%;
}

.route-link-div {
  width: 100%;
}

.route-link {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.route-link-active {
  width: 100%;
  background-color: #635ad9;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.route-link:hover {
  background-color: #635ad9;
  color: white;
  transition: all;
  transition-duration: 30ms;
}

.route-icon {
  width: 18px;
  height: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,0CAA0C;EAC1C,4CAA4C;AAC9C;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap\");\n\n* {\n  /* font-family: \"Open Sans\", sans-serif; */\n  font-family: \"Plus Jakarta Sans\", sans-serif;\n}\n\n:root,\nbody {\n  height: 100%;\n}\n\n.page-route-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  margin-top: 1rem;\n}\n\n.page-options {\n  width: 100%;\n}\n\n.route-link-div {\n  width: 100%;\n}\n\n.route-link {\n  width: 100%;\n  background-color: white;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n  text-decoration: none;\n  padding: 0.8rem;\n  border-radius: 0.5rem;\n}\n\n.route-link-active {\n  width: 100%;\n  background-color: #635ad9;\n  color: white;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 14px;\n  text-decoration: none;\n  padding: 0.8rem;\n  border-radius: 0.5rem;\n}\n\n.route-link:hover {\n  background-color: #635ad9;\n  color: white;\n  transition: all;\n  transition-duration: 30ms;\n}\n\n.route-icon {\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
