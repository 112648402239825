import React from "react";
import "./ContactOffice.css";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";
import icon1 from "./Image/Browser.png";
import icon2 from "./Image/Card.png";
import icon3 from "./Image/Preferences.png";
import { CiMail } from "react-icons/ci";
import { TbInfoSquareRoundedFilled } from "react-icons/tb";
import { IoMail } from "react-icons/io5";

const ContactOffice = () => {
  return (
    <>
      <section className="ContactOffice-section">
        <div className="ContactOffice-containr">
          <div className="ContactOffice-card-box">
            <img src={icon1} alt="" className="ContactOffice-card-icon-box" />
            <h2>Kolkata Office</h2>
            <ul>
              <li>
                <FaLocationDot />
                kolkata, India
              </li>
              <li>
                <FaPhoneAlt /> +91 8001440055
              </li>
              <li>
                <a
                  href="mailto:contact@pnsoftware.org"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <IoMail
                    style={{ width: "20px", height: "20px", fill: "#8084FF" }}
                  />{" "}
                  contact@pnsoftware.org
                </a>
              </li>
              <li>
                <a
                  href="mailto:billing@pnsoftware.org"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <TbInfoSquareRoundedFilled
                    style={{
                      width: "20px",
                      height: "20px",
                      fill: "#8084FF",
                    }}
                  />{" "}
                  billing@pnsoftware.org
                </a>
              </li>
            </ul>
          </div>
          <div className="ContactOffice-card-box">
            <img src={icon2} alt="" className="ContactOffice-card-icon-box" />
            <h2>UAE Office</h2>
            {/* <ul>
              <li>
                <FaLocationDot />
                kolkata, India
              </li>
              <li>
                <FaPhoneAlt /> +91 1234567890
              </li>
              <li>
                <IoMdTime /> abc@gmail.com
              </li>
            </ul> */}
            <p>
              <span style={{ fontWeight: 600 }}>Address : </span> Coming Soon...
            </p>
            <p style={{ marginTop: "0.5rem" }}>
              We're working hard to open our UAE office. Stay tuned for updates!
            </p>
          </div>
          <div className="ContactOffice-card-box">
            <img src={icon3} alt="" className="ContactOffice-card-icon-box-1" />
            <h2>USA Office</h2>
            {/* <ul>
              <li>
                <FaLocationDot />
                kolkata, India
              </li>
              <li>
                <FaPhoneAlt /> +91 1234567890
              </li>
              <li>
                <IoMdTime /> abc@gmail.com
              </li>
            </ul> */}
            <p>
              <span style={{ fontWeight: 600 }}>Address : </span> Coming Soon...
            </p>
            <p style={{ marginTop: "0.5rem" }}>
              We're working hard to open our USA office. Stay tuned for updates!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactOffice;
