// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding-layout {
  padding: 1rem 1rem 4rem 1rem;
}

.policy-container {
  max-width: 78rem;
  margin: 0 auto;
}

.policy-header {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
}

.policy-section {
  margin-bottom: 2rem;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.8rem;
  margin: 1.5rem 0 1rem;
}

.policy-section h3 {
  font-size: 1.4rem;
  margin: 1.2rem 0 0.8rem;
}

.policy-section p {
  line-height: 1.6;
  margin-bottom: 1rem;
}

.policy-section ul {
  margin: 1rem 0;
  padding-left: 2rem;
}

li {
  margin-bottom: 0.5rem;
}

.policy-footer {
  margin-top: 3rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e0e0e0;
}

@media (min-width: 640px) {
  .policy-container {
    padding: 2rem;
  }
}

@media (min-width: 1280px) {
  .mlm-main-container {
    flex-direction: row;
  }
  .padding-layout {
    padding: 5rem 2rem 5rem 2rem;
  }
}

@media (min-width: 1536px) {
  .padding-layout {
    padding: 4rem 8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Policies/Policy.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gCAAgC;EAChC,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".padding-layout {\n  padding: 1rem 1rem 4rem 1rem;\n}\n\n.policy-container {\n  max-width: 78rem;\n  margin: 0 auto;\n}\n\n.policy-header {\n  border-bottom: 1px solid #e0e0e0;\n  padding-bottom: 1.5rem;\n  margin-bottom: 2rem;\n}\n\n.policy-section {\n  margin-bottom: 2rem;\n}\n\nh1 {\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n}\n\nh2 {\n  font-size: 1.8rem;\n  margin: 1.5rem 0 1rem;\n}\n\n.policy-section h3 {\n  font-size: 1.4rem;\n  margin: 1.2rem 0 0.8rem;\n}\n\n.policy-section p {\n  line-height: 1.6;\n  margin-bottom: 1rem;\n}\n\n.policy-section ul {\n  margin: 1rem 0;\n  padding-left: 2rem;\n}\n\nli {\n  margin-bottom: 0.5rem;\n}\n\n.policy-footer {\n  margin-top: 3rem;\n  padding-top: 1.5rem;\n  border-top: 1px solid #e0e0e0;\n}\n\n@media (min-width: 640px) {\n  .policy-container {\n    padding: 2rem;\n  }\n}\n\n@media (min-width: 1280px) {\n  .mlm-main-container {\n    flex-direction: row;\n  }\n  .padding-layout {\n    padding: 5rem 2rem 5rem 2rem;\n  }\n}\n\n@media (min-width: 1536px) {\n  .padding-layout {\n    padding: 4rem 8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
