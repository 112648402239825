// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-white: #fff;
    --primary-black: #000;
    --primary-grey: #73739a;
    --primary-blue: #6d72f6;
    --primary-green: #08ce8a;
    --primary-black2: #1c1c57;
  }
  
  .contact-us-container {
    background: var(--primary-white);
    font-family: Arial, sans-serif;
  }
  
`, "",{"version":3,"sources":["webpack://./src/Pages/ContactUs/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;IACxB,yBAAyB;EAC3B;;EAEA;IACE,gCAAgC;IAChC,8BAA8B;EAChC","sourcesContent":[":root {\n    --primary-white: #fff;\n    --primary-black: #000;\n    --primary-grey: #73739a;\n    --primary-blue: #6d72f6;\n    --primary-green: #08ce8a;\n    --primary-black2: #1c1c57;\n  }\n  \n  .contact-us-container {\n    background: var(--primary-white);\n    font-family: Arial, sans-serif;\n  }\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
