import React from "react";
import "./ContactForm.css";
import { FaGlobeAmericas, FaPhoneAlt, FaRegClock } from "react-icons/fa";
import icon1 from "./Image/phone icon.png";
import icon2 from "./Image/message icon.png";
import icon3 from "./Image/location icon.png";
import icon4 from "./Image/Clock icon.png";

const ContactForm = () => {
  return (
    <>
      <section className="ContactForm-section">
        <div className="ContactForm-container">
          <div className="ContactForm-form-box">
            <form>
              <label htmlFor="name">Name:</label>
              <input type="text" name="name" id="name" required />
              <label htmlFor="number">Number:</label>
              <input type="number" name="number" id="number" required />
              <label htmlFor="email">Email:</label>
              <input type="email" name="email" id="email" required />
              <label htmlFor="message">Message:</label>
              <textarea name="message" id="message" required></textarea>
              <button>Submit</button>
            </form>
          </div>
          <div className="ContactForm-content-container">
            <div className="ContactForm-content-box">
              <div className="ContactForm-icon-box">
                <img src={icon1} alt="" className="ContactForm-icon-img" />
              </div>
              <div className="ContactForm-title-box">
                <h3>Call Us</h3>
                <p>
                  <span style={{ fontWeight: 600 }}>Mob:</span> +91 8001440055
                </p>
                <p>
                  <span style={{ fontWeight: 600 }}>Tel:</span> 033 35832221
                </p>
              </div>
            </div>
            <div className="ContactForm-content-box">
              <div className="ContactForm-icon-box">
                <img src={icon2} alt="" className="ContactForm-icon-img" />
              </div>
              <div className="ContactForm-title-box" style={{display: "flex", flexDirection: "column", gap: "2px"}}>
                <h3>Message</h3>
                <a href="mailto:contact@pnsoftware.org" style={{textDecoration: "none", color: "gray"}}>
                  contact@pnsoftware.org
                </a>
                <a href="mailto:info@pnsoftware.org" style={{textDecoration: "none", color: "gray"}}>
                  info@pnsoftware.org
                </a>
              </div>
            </div>
            <div className="ContactForm-content-box">
              <div className="ContactForm-icon-box">
                <img src={icon3} alt="" className="ContactForm-icon-img" />
              </div>
              <div className="ContactForm-title-box">
                <h3>Our Location</h3>
                <p>Kolkata, India</p>
              </div>
            </div>
            <div className="ContactForm-content-box">
              <div className="ContactForm-icon-box">
                <img src={icon4} alt="" className="ContactForm-icon-img" />
              </div>
              <div className="ContactForm-title-box">
                <h3>Working Hours</h3>
                <p>Mon-Sat 10:00 AM - 07:00 PM</p>
                <p>Sun 12:00 PM - 07:00 PM</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
