// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-white: #fff;
    --primary-black: #000;
    --primary-grey: #73739a;
    --primary-grey2: #f5f5f5;
    --primary-blue: #6d72f6;
    --primary-green: #08ce8a;
    --primary-black2: #1c1c57;
}


.Blogs-container .header-links-box a{
    color: var(--primary-white);
}

.Blogs-container .header-links-box a:hover{
    color: var(--primary-blue);
}

.Blogs-container .dropdown-content a{
    color: var(--primary-grey);
}

.Blogs-container .dropdown-content a:hover{
    color: var(--primary-blue);
}

.header-links-box.active a{
    color: var(--primary-black2);
}

.Blogs-container .hamburger-menu .line{
    background-color: var(--primary-white);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Blog/Blogs.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;IACvB,wBAAwB;IACxB,yBAAyB;AAC7B;;;AAGA;IACI,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,sCAAsC;AAC1C","sourcesContent":[":root {\n    --primary-white: #fff;\n    --primary-black: #000;\n    --primary-grey: #73739a;\n    --primary-grey2: #f5f5f5;\n    --primary-blue: #6d72f6;\n    --primary-green: #08ce8a;\n    --primary-black2: #1c1c57;\n}\n\n\n.Blogs-container .header-links-box a{\n    color: var(--primary-white);\n}\n\n.Blogs-container .header-links-box a:hover{\n    color: var(--primary-blue);\n}\n\n.Blogs-container .dropdown-content a{\n    color: var(--primary-grey);\n}\n\n.Blogs-container .dropdown-content a:hover{\n    color: var(--primary-blue);\n}\n\n.header-links-box.active a{\n    color: var(--primary-black2);\n}\n\n.Blogs-container .hamburger-menu .line{\n    background-color: var(--primary-white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
