// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-white: #fff;
    --primary-black: #000;
    --primary-grey: #73739a;
    --primary-grey2: #f5f5f5;
    --primary-blue: #6d72f6;
    --primary-green: #08ce8a;
    --primary-black2: #1c1c57;
}


.ContactOffice-section{
    padding: 3rem 20px;
}

.ContactOffice-containr{
    gap: 20px;
    display: flex;
}

.ContactOffice-card-box{
    width: 100%;
    z-index: 5;
    position: relative;
    border-radius: 30px;
    padding: 80px 40px 40px;
    background-color: #f1e9ff50;
    border: 1px solid #f3ebffcb;
}

.ContactOffice-card-icon-box{
    width: 55px;
    height: 55px;
    margin-bottom: 20px;
}

.ContactOffice-card-icon-box-1{
    width: 85px;
    height: 85px;
    margin-bottom: 20px;
}

.ContactOffice-card-box h2{
    font-size: 26px;
    margin-bottom: 22px;
    color: var(--primary-black);
}

.ContactOffice-card-box ul li{
    list-style: none;
    margin: 12px 0;
    gap: 10px;
    color: #444444;
    display: flex;
    align-items: center;
}

.ContactOffice-card-box ul li svg{
    color: var(--primary-blue);
}

@media (max-width: 768px){
    .ContactOffice-containr{
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ContactOffice/ContactOffice.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;IACvB,wBAAwB;IACxB,yBAAyB;AAC7B;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,SAAS;IACT,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[":root {\n    --primary-white: #fff;\n    --primary-black: #000;\n    --primary-grey: #73739a;\n    --primary-grey2: #f5f5f5;\n    --primary-blue: #6d72f6;\n    --primary-green: #08ce8a;\n    --primary-black2: #1c1c57;\n}\n\n\n.ContactOffice-section{\n    padding: 3rem 20px;\n}\n\n.ContactOffice-containr{\n    gap: 20px;\n    display: flex;\n}\n\n.ContactOffice-card-box{\n    width: 100%;\n    z-index: 5;\n    position: relative;\n    border-radius: 30px;\n    padding: 80px 40px 40px;\n    background-color: #f1e9ff50;\n    border: 1px solid #f3ebffcb;\n}\n\n.ContactOffice-card-icon-box{\n    width: 55px;\n    height: 55px;\n    margin-bottom: 20px;\n}\n\n.ContactOffice-card-icon-box-1{\n    width: 85px;\n    height: 85px;\n    margin-bottom: 20px;\n}\n\n.ContactOffice-card-box h2{\n    font-size: 26px;\n    margin-bottom: 22px;\n    color: var(--primary-black);\n}\n\n.ContactOffice-card-box ul li{\n    list-style: none;\n    margin: 12px 0;\n    gap: 10px;\n    color: #444444;\n    display: flex;\n    align-items: center;\n}\n\n.ContactOffice-card-box ul li svg{\n    color: var(--primary-blue);\n}\n\n@media (max-width: 768px){\n    .ContactOffice-containr{\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
